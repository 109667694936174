<style lang="scss" scoped>
.page-5-3{
    width: 100%;
}
.main{
    width: 100%;
}


          .tips-item {
            font-size: 14px;
            color: #4e5969;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 10px;
          }

          .tips-item:before {
            content: "";
            width: 5px;
            margin-right: 5px;
            height: 5px;
            float: left;
            background: #4e5969;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
          }
</style>

<template>
    <div class="page-5-3 page-child-classify">
        <div class="page-child-classify-title">5.3 运输贮存


</div>
        <div class="main">
             <div class="tips-item">若预计将电池存放30天以上的，应将SOC调整为40%±10%，三个月须进行一次满充放电。</div>
            <div class="tips-item">设备应储存在阴凉干燥处。</div>
            <div class="tips-item">运输时应做好防尘防雨等防护措施，并做好货物的摆放及固定，防止产生冲击及散落情况。</div>
            <div class="tips-item">装卸货物建议使用叉车，并由专业人员进行装卸操作。</div>
            <div class="tips-item">应按照外包装标识要求，禁止淋雨、暴晒、翻转、踩踏等。</div>
            <div class="tips-item">贮存环境温度应在-30℃~45℃之间，湿度应在5%～95%，无凝露。</div>
            <div class="tips-item">不得以任何方式擅自拆解系统相关装置、电池簇、电池模组及电池。</div>
            <div class="tips-item">带包装卸货贮存时应注意产品编号顺序，建议按编号集中贮存，以方便安装。</div>
            <div class="tips-item">到货后请立即进行货物验收。</div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>