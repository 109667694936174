<style lang="scss" scoped>
.page-5-2{
    width: 100%;
}
.main{
    width: 100%;
}

          .tips-item {
            font-size: 14px;
            color: #4e5969;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 10px;
          }

          .tips-item:before {
            content: "";
            width: 5px;
            margin-right: 5px;
            height: 5px;
            float: left;
            background: #4e5969;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
          }
</style>

<template>
    <div class="page-5-2 page-child-classify">
        <div class="page-child-classify-title">5.2 安装调试

</div>
        <div class="main">
            <div class="tips-item">系统在安装过程中，必须由专业人员进行操作，并且必须遵照地方或电力行业的有关规程进行。</div>
            <div class="tips-item">注意正负极标示，请勿将正负极反接，避免发生短路。</div>
            <div class="tips-item">该系统为直流高压电，除专业人员，未经允许其他人员应远离，并且不得触摸或操作。</div>
            <div class="tips-item">电池簇初始容量为30%-50%，第一次使用电池簇，需将电池充满电后再使用。</div>
            <div class="tips-item">系统在使用中，应远离火源、热源和水源，如果电池泄漏或发出异味，应立即停止使用，并及时通知相关人员进行处理。</div>
            <div class="tips-item">请勿用钉子或其它尖锐物体刺穿电池组壳体，不得锤击或踩踏、重压电池组。</div>
            <div class="tips-item">不得以任何方式擅自拆解系统相关装置、电池簇、电池模组及电池。</div>
            <div class="tips-item">系统在运行过程中，如果出现异味、异常现象，请立即断电，并告知相关人员进行处理。</div>
            <div class="tips-item">存储过程中应确保无任何外部设备从锂电池取电，以防止锂电池本体发生过放电。</div>
           
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>