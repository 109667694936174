<style lang="scss" scoped>
.page-4-3{
    width: 100%;
}
.main{
    width: 100%;
    height: 400px;
    background: rgb(65, 65, 65);
}
</style>

<template>
    <div class="page-4-3 page-child-classify">
        <div class="page-child-classify-title">4.3 国外运输

</div>
        <div class="main"></div>
    </div>
</template>

<script>
export default {
    
}
</script>