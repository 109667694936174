<style lang="scss" scoped>
.page-3-1{
    width: 100%;
}
.main{
    width: 100%;
}
</style>

<template>
    <div class="page-3-1 page-child-classify">
        <div class="page-child-classify-title">3.1 电池模组组装
</div>
        <div class="main">
            <video controls  width="100%" src="https://photovoltaic.linktwins.com/pic/MP4/install.mp4"></video>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>