<style lang="scss" scoped>
.page-3-3{
    width: 100%;
}
.main{
    width: 100%;

}
</style>

<template>
    <div class="page-3-3 page-child-classify">
        <div class="page-child-classify-title">3.3 EMS安装
</div>
        <div class="main">
            <video controls width="100%" src="http://dianbo.wvgl.com/b6c965a47b4149959b0d89510a0aea2b/7688fb253d60481d919cd93c112c7941-ec13881347221ddeff0375d5c3190c71-ld.mp4"></video>

        </div>
    </div>
</template>

<script>
export default {
    
}
</script>