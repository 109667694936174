<style lang="scss" scoped>
.page-4-2{
    width: 100%;
}
.main{
    width: 100%;
}
</style>

<template>
    <div class="page-4-2 page-child-classify">
        <div class="page-child-classify-title"> 国内运输
</div>
        <div class="main">
                <img src="@/assets/images/workspace/empty.png" style="width:100%" alt="">
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>